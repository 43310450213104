<template>
  <div class="page-container clear">
    <Header pad="50px 40px 40px 40px" :showDot="true">
      <div class="header-content V-alignment">
        <img src="../../assets/icon/icon-check-big.png" alt="" />
        <XText fw="900" c="#FFF" s="1rem" mg="24px 0 0px 0" lh="1.4">恭喜! 您的【{{q.projectName}}】专属分析报告已生成，支付后即可解锁!</XText>
      </div>
    </Header>
    <!-- main -->
    <div class="main-content V-alignment">
      <XText c="#3E3E3E" s=".9rem" fw="900" bkg="#F2F2F2" pad="6px 8px" br="10px" mg="0 0 20px 0">解锁后您将获得
      </XText>

      <!-- list item -->
      <div class="list-item H-alignment">
        <i class="iconfont icon-check_square_o"></i>
        <XText c="#3E3E3E" s=".9rem" fw="500">专业的心理健康综合解读报告</XText>
      </div>
      <div class="list-item H-alignment">
        <i class="iconfont icon-check_square_o"></i>
        <XText c="#3E3E3E" s=".9rem" fw="500">十大心理影响因素重点分析</XText>
      </div>
      <div class="list-item H-alignment">
        <i class="iconfont icon-check_square_o"></i>
        <XText c="#3E3E3E" s=".9rem" fw="500">针对性的自助改善建议</XText>
      </div>
      <div class="list-item H-alignment">
        <i class="iconfont icon-check_square_o"></i>
        <XText c="#3E3E3E" s=".9rem" fw="500">抑郁、焦虑等常见问题调节指南</XText>
      </div>
    </div>

    <!-- pay section -->
    <div class="pay-section H-alignment">
      <!-- <i class="iconfont icon-weixinzhifu"></i> -->
      <img src="../../assets/icon/icon-alipay.png" alt="" />
      <div class="V-alignment" style="align-items: flex-start;">
        <XText c="#3E3E3E" s=".9rem" fw="500" mg="0 0 0 10px">支付宝</XText>
        <XText c="#8E8E8E" s=".6rem" fw="400" mg="3px 0 0 10px">10亿人都在用， 真安全， 真方便</XText>
      </div>
      <XText c="#F56C6C" s="1.3rem" fw="900"><span style="font-size: .9rem; margin-right: 4px">￥</span>{{q.price}}</XText>
    </div>

    <!-- pay button -->
    <el-button type="primary" round @click="unlock">立即支付解锁专属报告</el-button>
  </div>
</template>

<script>
  import { getQuestionInfo } from 'api/q'

  // @components
  import Header from 'components/common/Header'
  import XText  from 'components/common/XText'

  export default {
    name: 'QPay',
    components: {
      Header, XText,
    },
    async mounted() {
      const res = await getQuestionInfo(this.$route.query.pid);
      this.q = res.resData
    },
    methods: {
      async unlock() {
        // this.$message("")
      }
    },
    computed: {
    },
    data() {
      return {
        q: { },
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-container {
    background: #F2F2F2;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .header-content {
    position: relative;
    z-index: 100;
    width: 100%;
  }

  .header-content > img {
    width: 25vw;
    height: 25vw;
  }

  .main-content {
    position: relative;
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-top: -10px;
    background: #FFF;
    z-index: 100;
    border-radius: 10px;
    align-items: flex-start;
    padding: 16px;
  }

  .list-item {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 6px;
  }

  .list-item > i {
    color: #409EFF;
    font-size: 1.6rem;
    margin-right: 10px;
  }

  .pay-section {
    position: relative;
    width: calc(100vw - 40px);
    margin-top: 20px;
    margin-left: 20px;
    background: #FFF;
    z-index: 100;
    border-radius: 10px;
    justify-content: flex-start;
    padding: 10px 16px;
  }

  .pay-section > img {
    /*color: #67C23A;
    font-size: 2rem;*/
    width: 30px;
  }

  .pay-section > p:nth-child(3) {
    position: absolute;
    /*top: 15px;*/
    right: 20px;
  }

  .el-button {
    width: 70%;
    height: 46px;
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 900;
  }

  /*  css for media query */
  @media screen and (min-width: 600px) {
    .main-content {
      grid-template-columns: repeat(3, calc(33.3333% - 8px));
    }

    /deep/ .el-carousel {
      height: 30vw !important;
    }

    /deep/ .el-dialog {
      width: 50% !important;
    }
  }

  /*  css for media query */
  @media screen and (min-width: 600px) {
    .header-content img {
      width: 25vw;
      height: 25vw;
    }

    .el-button {
      width: auto;
    }
  }

  @media screen and (min-width: 900px) {
    .header-content img {
      width: 20vw;
      height: 20vw;
    }

    .el-button {
      width: auto;
    }
  }

  @media screen and (min-width: 1200px) {
    .header-content img {
      width: 15vw;
      height: 15vw;
    }

    .el-button {
      width: auto;
    }
  }
</style>
